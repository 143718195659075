export const fontSize1 = '0.875rem';
export const fontSize2 = '1rem';
export const fontSize3 = '1.125rem';
export const fontSize4 = '1.375rem';
export const fontSize5 = '1.5rem';
export const fontSize6 = '1.625rem';
export const fontSize7 = '1.75rem';
export const fontSize8 = '1.875rem';
export const fontSize9 = '2rem';
export const fontSize10 = '2.25rem';
