import type { MouseEventHandler } from 'react';
import React from 'react';
import styled from 'styled-components';
import { colorTextInverse, TextLinkButton } from 'lib/design-system';
import { fontWeightSemibold, spacing5 } from 'lib/mortar';

const Wrapper = styled.div`
  color: ${colorTextInverse};
  margin-top: ${spacing5};
  font-weight: ${fontWeightSemibold};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Button = styled(TextLinkButton)`
  text-decoration: underline;
  &,
  &:hover {
    color: ${colorTextInverse};
  }
`;

const SearchSwitch = ({ searchType, onSwitch }: SearchSwitchProps) => (
  <Wrapper>
    Or{' '}
    <Button onClick={onSwitch}>
      {searchType === 'category'
        ? 'look up a member by name'
        : 'search for a tradesperson near you'}
    </Button>
  </Wrapper>
);

interface SearchSwitchProps {
  searchType: 'category' | 'trade';
  onSwitch: MouseEventHandler;
}

export default SearchSwitch;
