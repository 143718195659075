export const breakpointSmall = {
  end: '599px',
};

export const breakpointMedium = {
  start: '600px',
  end: '1023px',
};

export const breakpointLarge = {
  start: '1024px',
  end: '1439px',
};

export const breakpointExtraLarge = {
  start: '1440px',
};
