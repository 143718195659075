import type { ReactNode } from 'react';
import styled from 'styled-components';

import {
  breakpointMedium,
  borderRadiusMain,
  colorTextLinkPressed,
  fontFamilyMain,
  fontSize8,
  fontSize10,
  letterSpacingMedium,
  lineHeight6,
  lineHeight8,
  letterSpacingLarge,
  fontSize2,
  fontSize3,
  fontSize4,
  fontSize5,
  fontSize6,
  fontSize7,
  fontSize9,
  lineHeight2,
  lineHeight3,
  lineHeight4,
  lineHeight5,
  lineHeight7,
  fontSize1,
  lineHeight1,
} from '../../design-tokens';
import {
  fontWeightRegular,
  fontWeightSemibold,
  neutral800,
  default700,
  tabbed500,
  neutral500,
  neutral0,
  default500,
} from 'lib/mortar/design-tokens';

export type HeadingTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span';

type HeadingColor = 'primary' | 'secondary' | 'inverse';

const ColorsMap: Record<HeadingColor, string> = {
  primary: default700,
  secondary: neutral800,
  inverse: neutral0,
};

interface HeadingProps {
  children: ReactNode;
  color?: HeadingColor;
  as: HeadingTags;
}

const common = `
  margin: 0;
  padding: 0;
  font-family: ${fontFamilyMain}
`;

const commonHeading = ({ color = 'primary' }: HeadingProps) => `
  ${common};
  color: ${ColorsMap[color]};
  font-weight: ${fontWeightSemibold};
`;

export const commonBody = `
  ${common};
  font-weight: ${fontWeightRegular};
  color: ${neutral800};
`;

const commonLink = `
  ${common};
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeightSemibold};
  color: ${default500};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: ${borderRadiusMain} solid ${tabbed500};
  }

  &:active {
    color: ${colorTextLinkPressed};
    text-decoration: none;
  }
`;

export const sizeAtBreakpointMedium = (
  fontSize: string,
  lineHeight: string,
) => `
  @media (min-width:${breakpointMedium.start}) {
    font-size: ${fontSize};
    line-height: ${lineHeight};
  }
`;

export const HeadingXXL = styled.h1<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize8};
  line-height: ${lineHeight6};
  letter-spacing: ${letterSpacingMedium};
  ${sizeAtBreakpointMedium(fontSize10, lineHeight8)};
`;

export const HeadingXL = styled.h2<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize6};
  line-height: ${lineHeight5};
  letter-spacing: ${letterSpacingMedium};
  ${sizeAtBreakpointMedium(fontSize9, lineHeight7)};
`;

export const HeadingL = styled.h3<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize4};
  line-height: ${lineHeight4};
  letter-spacing: ${letterSpacingMedium};
  ${sizeAtBreakpointMedium(fontSize7, lineHeight6)};
`;

export const HeadingM = styled.h4<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize3};
  line-height: ${lineHeight2};
  letter-spacing: ${letterSpacingMedium};
  ${sizeAtBreakpointMedium(fontSize5, lineHeight5)};
`;

export const HeadingS = styled.h5<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize2};
  line-height: ${lineHeight2};
  letter-spacing: ${letterSpacingLarge};
  ${sizeAtBreakpointMedium(fontSize3, lineHeight3)};
`;

export const HeadingXS = styled.h6<HeadingProps>`
  ${commonHeading};
  font-size: ${fontSize2};
  line-height: ${lineHeight2};
  letter-spacing: ${letterSpacingLarge};
  ${sizeAtBreakpointMedium(fontSize2, lineHeight2)};
`;

export const BodyText = styled.p`
  ${commonBody};
  font-size: ${fontSize2};
  line-height: ${lineHeight2};
  strong {
    font-weight: 600;
  }
`;

export const BodyTextSmall = styled.p`
  ${commonBody};
  font-size: ${fontSize1};
  line-height: ${lineHeight1};
`;

export const TextLink = styled.a`
  ${commonLink};
  font-size: ${fontSize2};
  line-height: ${lineHeight2};
  letter-spacing: ${letterSpacingLarge};
`;

export const TextLinkButton = styled.button`
  ${commonLink};
  font-size: ${fontSize2};
  line-height: ${lineHeight2};
  letter-spacing: ${letterSpacingLarge};

  &:disabled {
    color: ${neutral500};
    text-decoration: none;
    pointer-events: none;
  }
`;

export const TextLinkSmall = styled.a`
  ${commonLink};
  font-size: ${fontSize1};
  line-height: ${lineHeight1};
`;
